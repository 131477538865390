import { ConfigCatWebProvider } from "@openfeature/config-cat-web-provider";
import { Provider } from "@openfeature/web-sdk";
import { LogLevel, OptionsForPollingMode, PollingMode, createConsoleLogger } from "configcat-js";
import { FEATURE_FLAG_API_KEY } from "../../common/env";
import { IFeatureFlagProviderFactory } from "../../interfaces/feature-flag-provider-factory.interface";

export class ConfigCatProviderFactory implements IFeatureFlagProviderFactory {
  private _FEATURE_FLAG_API_KEY = FEATURE_FLAG_API_KEY;

  create(): Provider {
    const apiKey = this._getApiKey();
    const options = this._getProviderConfig();
    const provider = ConfigCatWebProvider.create(apiKey, options);

    return provider;
  }

  private _getProviderConfig(): OptionsForPollingMode<PollingMode.LazyLoad> {
    const logger = createConsoleLogger(LogLevel.Warn);

    return {
      logger,
      setupHooks: (hooks: any) => hooks.on("clientReady", () => console.debug("ConfigCat Client is ready!")),
    };
  }

  private _getApiKey() {
    return this._FEATURE_FLAG_API_KEY;
  }
}
